.cms-page-view, .wordpress-post-view {
  #maincontent {
    padding: 40px 0 0;
    @include media($tablet-down) {
      padding: 0;
    }
    @include media($mobile-down) {
      padding: 30px 0 0;
    }
  }
}

.wordpress-post-view {
  #maincontent {
    .breadcrumbs {
      background-color: $background-tertiary;
      width: 100%!important;
      float: none!important;
      padding: 0!important;
      ul {
        max-width: $max-width!important;
        margin: 0 auto!important;
        padding: 20px 70px 0!important;
        @include media($tablet-down) {
          padding: 20px 30px!important;
        }
      }
    }
  }
}
