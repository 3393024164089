
// --------------------------------------------------------------------------
//   Initialise media query order
// --------------------------------------------------------------------------

@include media($large-desktop-down) { /* */ }
@include media($wrapper-down) { /* */ }
@include media($desktop-down) { /* */ }
@include media($tablet-down) { /* */ }
@include media($mobile-down) { /* */ }

@include media($mobile-up) { /* */ }
@include media($tablet-up) { /* */ }
@include media($desktop-up) { /* */ }
@include media($wrapper-up) { /* */ }
@include media($large-desktop-up) { /* */ }


// --------------------------------------------------------------------------
//   Centering mixin for containers
// --------------------------------------------------------------------------

@mixin center {
  @include outer-container;

  @include media($desktop-down) {
    width: $desktop;
  }

  @include media($tablet-down) {
    width: $tablet;
  }

  @include media($mobile-down) {
    max-width: $mobile;
    width: auto;
  }
}

@mixin grid($columns: 4, $tabletWidth: 3, $mobileWidth: 2){
  width: calc(100% / #{$columns});
  float: left;
  @include media($larger-desktop-up) {
    width: calc(100% / 6);
  }
  @include media($larger-desktop-down) {
    width: calc(100% / 5);
  }
 @include media($large-desktop-down) {
    width: calc(100% / 5);
  }
  @include media($wrapper-down) {
    width: calc(100% / 4);
  }
  @include media($desktop-down) {
    width: calc(100% / #{$columns});
  }
  @include media($tablet-down) {
    width: calc(100% / #{$tabletWidth});
  }
  @include media($mobile-down) {
    width: calc(100% / #{$mobileWidth});
  }
  @include media($mobile-small-down) {
    width: 100%;
  }
}
