#service-list {
  display: block;
  padding: $section-divider 0;
  @include media($tablet-down) {
    padding: 0;
  }
  &.gray-theme {
    background: $background-tertiary;
  }
  &.white-theme {
    background: $white;
  }
}

.service-list {
  display: block;
  padding: 50px;
  @include media($tablet-down) {
    padding: 50px 40px 40px;
  }
  @include media($mobile-down) {
    padding: 40px 20px 30px;
  }

  .heading {
    display: block;
    width: 100%;
    font-family: $heading-bold;
    color: $tertiary;
    margin-bottom: 60px;
    @include media($tablet-down) {
      margin-bottom: 40px;
    }
    @include media($mobile-down) {
      margin-bottom: 20px;
    }
  }



  .services {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;

    @include media($tablet-down) {
      display: block;
    }

    .slick-dots {
      @include media($tablet-down) {
        padding: 0;
      }
    }

    .service {
      width: 30%;
      display: flex;
      flex-direction: row;
      outline: none;
      text-decoration: none;

      &:hover {
        cursor: pointer;
      }

      @include media($tablet-down) {
        width: 50%;
      }


      .icon-wrapper {
        margin-right: 20px;
        .icon {
          height: 100%;
          width: 100%;
          max-height: 100px;
          max-width: 100px;
        }
      }

      .text-content {
        padding: 20px 0;

        .title {
          color: $secondary;
          font-family: $heading-bold;
          vertical-align: top;
          padding: 0 0 0 5px;
          @include media($mobile-down) {
            font-family: $heading-family__base;
          }
        }

        ul {
          vertical-align: middle;
          font-size: 16px;
          padding: 0 0 20px;
          margin: 0 0 0 5px;
          list-style-type: none;

          li {
            line-height: 2;
            color: $primary;
          }
        }
      }
    }
  }
}
