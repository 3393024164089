.breadcrumbs {
    @include lib-breadcrumbs();
}

.breadcrumbs {
    margin: 0;
    max-width: none;
    padding: 20px 40px 0;
    float: left;
    width: 50%;
    .items {
        .item {
            a, strong {
                color: $primary;
                font-size: 14px;
            }
            &:after {
                line-height: 25px!important;
            }
            strong {
                font-weight: bold;
            }
        }
    }
    @include media($tablet-down) {

    }
    @include media($medium-down) {
        width: 100%;
        padding: 20px 40px 15px;
    }
}
