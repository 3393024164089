//
//  Variables
//  _____________________________________________

$gift-wrapping__border-color                : $color-gray-light5 !default;
$gift-wrapping__border-width                : $border-width__base !default;

$gift-wrapping-preview__active__border-color: $color-gray20 !default;
$gift-wrapping-preview__active__border-width: 3px !default;

$gift-wrapping-thumbnail__height            : 4rem !default;
$gift-wrapping-thumbnail__width             : $gift-wrapping-thumbnail__height !default;

$gift-wrapping-no-image__background         : $color-gray95 !default;
$gift-wrapping-no-image-icon__color         : $color-gray-darken4 !default;
$gift-wrapping-no-image-icon__font-size     : 22px !default;
