
.hero {
  width: 100%;
  height: calc(100vh - 300px);
  max-height: 800px;
  min-height: 400px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  // box-shadow: $box-shadow;
  @include media($large-desktop-up) {
    max-height: 1000px;
  }
  .wrap {
    position: relative;
    padding: 0;
    height: 100%;
    .text-content {
      display: inline-block;
      width: 50%;
      max-width: 635px;
      position: absolute;
      bottom: 15%;
      left: 0%;
      color: $white;
      // background: linear-gradient(75deg, rgba($secondary, 0.9) 0%, rgba($secondary, 0.9) 30%, rgba($tertiary, 0.9) 50%,rgba($tertiary, 0.9) 65%, rgba($tertiary, 0.95) 100%);
      background: linear-gradient(45deg, rgba($secondary, 0.95), rgba($tertiary, 0.8));
      padding: 20px 20px 10px 30px;
      @include media($tablet-down) {
        width: 100%;
      }
      @include media($mobile-down) {
        // left: auto;
        bottom: 10%;
        width: 90%;
        margin: 0 5%;
        max-width: none;
      }

      .headline {
        width: 100%;
        padding: 0;
        margin-bottom: 10px;

        h1 {
          font-weight: normal;
          margin: 0;
          padding: 0;
          font-size: 40px;
          @include media($tablet-down) {
            font-size: 40px;
          }
          @include media($mobile-small-down) {
            font-size: 35px;
          }
        }
      }

      .subtitle {
        width: 100%;
        padding: 0;
        padding-bottom: 20px;
        line-height: 1.9em;
        @include media($mobile-small-down) {
          display: none;
        }

        p {
          font-size: 17px;
          font-weight: 300;
          margin: 0;
          padding: 0;
          @include media($tablet-down) {
            font-size: 16px;
          }
        }
      }
    }
  }
}
