
#showcase {
  background-color: $background-tertiary;
  padding: $section-divider 0;
  @include media($tablet-down) {
    padding: 0;
  }
  .wrap {
    position: relative;
    @include media($tablet-down) {
      padding: 0px;
    }

    .featured-items {
      .item {
        display: flex;
        margin: 20px 60px;
        flex-direction: row-reverse;
        outline: none;

        @include media($tablet-down) {
          margin: 0px;
          flex-direction: column;
        }

        .image-wrap {
          width: 50%;
          height: $grid-item-height;
          max-height: 510px;
          padding: 20px;

          @include media($tablet-down) {
            padding: 0px;
          }

          .image {
            width: 100%;
            height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }
        //   img {
        //       height: 100%;
        //       width: 100%;
        //   }

          @include media($tablet-down) {
            width: 100%;
            img {
                height: 100%;
                width: 100%;
                display: block;
            }
          }
          @include media($mobile-down) {
            height: $grid-item-height / 2;
          }
        }

        .text-block {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 50%;
          justify-content: center;
          padding: 10px 100px 10px 0px;

          @include media($tablet-down) {
            width: 100%;
            flex-basis: auto;
            padding: 50px;
          }

          @include media($mobile-down) {
            padding: 40px 30px;
          }

          .text-content {
            padding-bottom: 50px;

            @include media($tablet-down) {
              padding-bottom: 0;
            }

            .title {
              font-family: $heading-bold;
              // font-weight: $font-weight__bold;
              color: $tertiary;
              margin-bottom: 20px;
            }
          }
          .more-button {
            position: absolute;
            bottom: 0;
            left: 0;
            margin: 20px 0px 0px;

            @include media($tablet-down) {
              left: 50px;
            }

            &::before {
              content: '';
              display: inline-block;
              height: 40px;
              width: 40px;
              background-image: url('../images/MoreButton.svg');
              background-position: center;
              background-repeat: repeat;
              background-size: contain;
              margin-right: 10px;
            }

            span {
              font-family: $heading-bold;
              text-transform: uppercase;
              font-size: 18px;
              color: $secondary;
              height: 40px;
              line-height: 40px;
              vertical-align: top;
              transition: 0.3s ease;
            }

            &:hover {
              text-decoration: none;
              cursor: pointer;
              span {
                color: $tertiary;
              }
            }
          }
        }

      }
    }
    .slide-nav {
      display: block;
      position: absolute;
      bottom: 0%;
      width: auto;
      right: 50%;
      padding: 0px 90px 30px 0;

      @include media($tablet-down) {
        position: static;
        width: 100%;
        padding: 0px 50px 50px;
      }
      @include media($mobile-down) {
        text-align: center;
        padding: 0px 30px 40px;
      }

      .slick-dots {
        float: right;
        display: inline-block;
        width: auto;
        margin: 0px 20px;
        height: 40px;
        line-height: 40px;
        vertical-align: text-bottom;
        padding: 0;
        @include media($mobile-down) {
          float: none;
        }
      }
    }
  }
}
