.pages {
    @include lib-pager();
    float: right;
    padding: 10px 40px 0;
    @include media($mobile-down) {
      float: none;
    }

    .action {
        width: 34px;

        &.previous {
            margin-right: 12px;
        }

        &.next {
            margin-left: 12px;
        }
    }
    .items {
        .item {
            margin: 0 6px 0 0;
            padding: 2px 10px;
            background-color: $white;
            transition: all 0.3s ease-in;
            border-radius: 5px;
            &:last-child {
              margin: 0;
            }
            &:hover, &.current {
                background-color: $secondary;
                .page {
                    .number {
                        color: $white;
                    }
                }
            }
            .page {
                .label {
                }
                .number {
                    font-size: 14px;
                    color: $primary;
                }
            }
            &.pages-item-next, &.pages-item-previous {
                padding: 2px 0;
                a {
                    font-size: 14px;
                    border: 0;
                    width: auto;
                    margin: 0;
                    &:before {
                        font-size: 35px;
                        margin: 0;
                        color: $primary;
                    }
                }
                &:hover {
                    a {
                        &:before {
                            color: $white;
                        }
                    }
                }
            }
         }
    }
}
