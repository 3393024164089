/* ------------------------------------------------------
  Portfolio
------------------------------------------------------ */
$tileHeight: 400px;
$tileWidth: 380px;
#portfolio {
  display: block;
  .wrap {
    padding: 0;
  }
  .case-studies {
    margin: 0;
    padding: 0;
    @include clearfix();
    li {
      position: relative;
      @include grid;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      display: inline-block;
      height: $tileHeight;
      max-height: $tileHeight;
      .tile-contents {
        height: 100%;
        width: 100%;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        .tile-wrap {
          @include center-align();
          text-align: center;
          color: $background-tertiary;
          font-family: $heading-bold;
          font-weight: bold;
          width: 100%;
          padding: 0 20px;
          h4 {
            padding: 0 0 15px;
            font-size: 24px;
          }
          a {
            padding: 8px 20px;
            background: $background-tertiary;
            border-radius: 5px;
            font-size: 16px;
            color: $secondary;
            text-transform: uppercase;
            // background-image: url('../images/button_bg.svg');
            // background-position: center;
            // background-size: contain;
            // background-repeat: no-repeat;
            text-decoration: none;
          }
        }
      }

      &:hover {
        .tile-contents {
          visibility: visible;
          opacity: 1;
          background-color: rgba($secondary, 0.9);
        }
      }
      @include media($large-desktop-down) {
        height: 350px;
      }
        @include media($wrapper-down) {
            height: 350px;
      }
      @include media($desktop-down) {
        height: 350px;
      }
      @include media($tablet-down) {
        .tile-contents {
            visibility: visible;
            opacity: 1;
            background-color: rgba($secondary, 0.6);
            .tile-wrap {
                h4 {
                    font-family: $heading-bold;
                    font-size: 30px;
                    color: $background-tertiary;
                }
                // a {
                //     padding: 10px 50px;
                //     font-size: 22px;
                // }
            }
            // background-color: rgba($secondary, 0.6);
          }
      }

    }
  }
}
