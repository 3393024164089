.product-add-form {
  display: block;
  clear:both;
  margin-top: 30px;
  .grouped {
      #super-product-table {
        font-size: 16px;
          thead {
              background-color: $secondary;

              color: $white;

              th {
                  text-align: center;
              }
          }
            tbody {
                text-align: center;
                color: #666666;
                border: 1px solid $bordergrey;
                tr {
                    background-color: $white;
                    &:nth-child(2n) {
                        background-color: $background-tertiary;
                    }
                }
                td {
                    border: 0;
                    .price-box {
                      display: inline!important;
                    }
                    .control {
                        position: relative;
                        width: 180px;
                        margin: 0 auto;
                        $toggleColor: #666666;
                        .toggle {
                            height: 34px;
                            display: inline-block;
                            position: absolute;
                            border-radius: 20px;
                            background-color: $toggleColor;
                            padding: 0 50px 0;
                            z-index: 2;
                            cursor: pointer;
                            &:after {
                                display: inline-block;
                                font-family: FontAwesome;
                                font-size: 10px;
                                font-weight: normal;
                                position: absolute;
                                color: $white;
                                z-index: 5;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                            &.prev {
                                left: 30px;
                                &:after {
                                    left: 15px;
                                    content: "\f068";
                                }
                            }
                            &.next {
                                right: 30px;
                                &:after {
                                    right: 15px;
                                    content: "\f067";
                                }
                            }
                        }
                        .qty-input {
                            position: relative;
                            display: inline-block;
                            padding: 0 10px;
                            border: 2px solid $toggleColor;
                            background-color: $white;
                            z-index: 9;
                        }
                        .input-text {
                            padding: 0 20px;

                        }
                        input[type=number] {
                            opacity: 1.0;
                            // visibility: hidden;
                            // display: none;
                            margin: 1px 0 0;
                            position: relative;
                            display: inline-block;
                            padding: 0 10px;
                            border: 2px solid $toggleColor;
                            background-color: $white;
                            z-index: 9;
                        }
                    }
                }
            }
      }
  }

  .box-tocart {
      padding: 30px 0 0;
      .fieldset {
          .actions {
              float: right;
              .action {
                &.primary {
                    border-radius: 30px;
                    background-color: $tertiary;
                    border: 0;
                    padding: 10px 30px;
                    span {
                        font-family: $font-family-name__base;
                        font-weight: normal;
                        text-transform: uppercase;
                    }
                }
              }
          }
      }
  }
}
