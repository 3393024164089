.product.data.items {
    @include lib-data-accordion();
    margin-bottom: $indent__base;
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product.data.items {
        @include lib-data-tabs();
    }
}

@include max-screen($screen__m) {
    .product.data.items {
        @include lib-data-accordion();
        .data.item {
            display: block;
        }
    }
}
