//
//  Variables
//  _____________________________________________

$active-nav-indent: 54px;

//
//  Common
//  _____________________________________________

// Header
header {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 999;
  transition: width ease-out 0.1s;
  // Top Menu Nav
  .wrapper {
    background-color: $secondary;
    color: $white;
    .header {
      padding: 0 5px !important;
      max-width: $max-width !important;
      @include clearfix();
    }
    .links {
        float: right;
        color: $white;
        li {
            padding: 4px 10px;
            a {
                // NON-ECOMMERCE
                // pointer-events: none;
                // REMOVE LATER
                color: $white;
                font-size: 16px;
                @include transition();
                &:after {
                    display: inline-block;
                    font-family: FontAwesome;
                    font-size: 16px;
                    margin: 0 0 0 10px;
                    top: 5px;
                }
                &.contact-us-link {
                    &:after {
                        content: "\f095";
                        transform: rotateY(180deg);
                    }
                }
                &.help-link {
                  pointer-events: none;
                    &:after {
                        content: '\f1cd';
                    }
                }
                &.myaccount-link {
                    &:after {
                        content: '\f007';
                    }
                }
                // REMOVE FOR NON-ECOMMERCE DEPLOYMENT
                &:hover {
                    // color: $tertiary-lighter;
                    // cursor: pointer;
                    text-decoration: none;
                }
            }
        }
    }
  }
  @include media($tablet-down) {
    .wrapper {
        .header {
            .links {
                margin: 0;
                list-style: none;
                li {
                    display: inline-block;
                }
            }
        }
    }
    .content {

    }
  }
  @include media($mobile-down) {
    .content {
        .minicart-wrapper {
            // padding: 0;
            .showcart {
                .counter-price {
                    display: none;
                }
            }
        }
    }
  }
  // @include media($mobile-small-down) {
  //   .wrapper {
  //       .header {
  //           .links {
  //               li {
  //                   a {
  //                       font-size: 0;
  //                   }
  //               }
  //           }
  //       }
  //   }
  // }

  // Main Menu Nav
  .content {
    position: relative;
    padding: 0 !important;
    display: block;
    max-width: none !important;
    height: $lower-banner-height;
    background-color: $background-tertiary;
    .block-search {
        display: none;
    }
    .wrap {
      height: 100%;
      display: block;
      @include clearfix();
      .brand {
        position: relative;
        width: 33%;
        height: 100%;
        display: inline-block;
        float: left;
      }
      $nav-text: #191919;
      .site-nav {
        display: inline-block;
        height: 100%;
        font-family: '#{$montserrat_family}#{$bold}';
        text-align: center;
        // line-height: $lower-banner-height;
        line-height: 84px;
        @include media($tablet-down) {
          overflow: auto;
        }

        .nav-list {
        //   height: 100%;
          padding: 0;
          margin: 0;
          > li {
            position: relative;
            display: inline-block;
            padding: 0 15px;
            a {
                color: rgba($nav-text, 0.7);
                transition: all .3s ease;
                &:hover, &:focus {
                    text-decoration: none;
                }
            }
            @include media($tablet-down) {
              padding: 0;
            }
            @include media($tablet-up) {
              &:hover {
                > ul {
                  visibility: visible;
                  opacity: 1;
                  max-height: none;
                }
              }
              a {
                &:hover, &:focus {
                    color: $nav-text;
                }
              }
            }
            @include media($tablet-down) {
              display: block!important;
              margin: 0 0 2px;
              text-align: left;
              height: 50px!important;
            }
            // @include media($mobile-down) {
            //   height: 50px!important;
            // }
            a {
                color: rgba($nav-text, 0.7);
                font-family: $font-medium;
                @include media($tablet-down) {
                    display: inline-block !important;
                    color: $tertiary;
                    font-size: 30px;
                    line-height: 1.1em;
                    font-family: $font-family-name__base;
                    transition: all 0.3s ease-in;
                }
            }
            &.parent {
                @include media($tablet-up) {
                    padding-right: 20px;
                    &:after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: 0;
                        width: 13px;
                        height: 13px;
                        background-image: url(../images/HomeButtonPurple.svg);
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center;
                        transform: rotate(180deg) translateY(50%);
                    }
                }
                @include media($tablet-down) {
                    height: auto!important;
                    padding-right: 15px;
                }
                @include media($mobile-down) {
                    overflow: hidden;
                    height: 50px!important;
                    &:after {
                        content: '';
                        position: absolute;
                        top: 28px;
                        right: 0px;
                        width: 22px;
                        height: 22px;
                        background-image: url(../images/HomeButtonPurple.svg);
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center;
                        transform: rotate(180deg);
                        transition: transform .2s ease-in-out;
                    }
                }
            }
            &.open {
              @include media($mobile-down) {
                height: auto!important;
                &:after {
                  transform: rotate(0deg);
                }
                > ul {
                  visibility: visible;
                  opacity: 1;
                  max-height: 100vh;
                }
              }
            }
            > ul {
              visibility: hidden;
              opacity: 0;
              transition: all .4s ease;
              position: absolute;
              top: 100%;
              left: 0;
              background: $background-tertiary;
              padding: 15px;
              min-width: 140px;
              max-width: 300px;
              z-index: -1;
              box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
              @include media($tablet-down) {
                visibility: visible;
                opacity: 1;
                padding: 0;
                position: relative;
                top: auto;
                background: transparent;
                box-shadow: none;
                z-index: auto;
              }
              @include media($mobile-down) {
                visibility: hidden;
                opacity: 0;
                max-height: 0;
                padding: 0;
              }
              > li {
                display: block;
                text-align: left;
                line-height: 1.4;
                margin-bottom: 15px;
                padding: 0;
                height: auto;
                &:last-child {
                  margin-bottom: 10px;
                }
                a {
                  white-space: pre;
                  @include media($tablet-down) {
                    font-size: 26px;
                  }
                  @include media($mobile-down) {
                    font-size: 22px;
                  }
                }
              }
            }
          }
        }
        #contact-nav {
            display: none;
            float: left;
            width: 50%;
            margin: 0 5% 0 0;
            li {
                font-size: 18px;
                text-align: left;
                width: 100%;
                display: block;
                line-height: 2.1em;
                font-family: $font-family-name__base;
                color: $secondary!important;
                a {
                    color: $secondary!important;
                    text-decoration: none!important;
                }
                &:last-child(){
                    padding: 15px 0 0;
                    .mobile-nav-map {
                        display: none;
                        height: 350px;
                        background-color: grey;
                        @include media($tablet-down) {
                            display: block;
                            width: 100%;
                        }
                    }
                }
            }
        }
      }
    }
  }
  @include media($tablet-down) {
    $nav-width: 25px;
    .content {
        height: 60px;
        &.active {
            background-color: $secondary;
        }
        .wrap {
            .brand {
                .nav-toggle {
                    display: block;
                    position: fixed;
                    right: 15px;
                    // top: 50px;
                    left: auto;
                    width: $nav-width;
                    height: 30px;
                    &:before {
                        display: none;
                        color: $tertiary;
                    }
                    $navline-height: 3px;
                    span {
                        display: block;
                        height: $navline-height;
                        width: $nav-width;
                        @include vertical-align(relative);
                        background-color: $tertiary;
                        overflow: visible;
                        margin: 0px;
                        transition: all 0.3s 0.2s;
                        &:before, &:after {
                            content: '';
                            display: block;
                            @include vertical-align(relative);
                            background-color: $tertiary;
                            height: $navline-height;
                            width: $nav-width;
                            transition: all 0.3s 0.2s;
                        }
                        &:before {
                            top: -6px;
                        }
                        &:after {
                            top: 6px;
                        }
                    }
                    &.active {
                        span {
                            background: transparent;
                            &:before {
                                transform: rotate(225deg);
                                top: 3px;
                            }
                            &:after {
                                transform: rotate(-45deg);
                                top: 0px;
                            }
                            &:hover {
                                &:before{
                                    transform: scale(1.1) rotate(225deg);
                                }
                                &:after {
                                    transform: scale(1.1) rotate(-45deg);
                                }
                            }
                        }
                    }
                }
                &.active {
                    .logo {
                        background-image: url('../images/CascadaWhiteLogo.svg') !important;
                    }
                }
            }
            .logo {
                width: 180px;
                margin: 0 0 10px 10px;
            }
            .site-nav {
                display: block;
                margin: 60px 0 0;
                padding: 30px 0 0;
                position: fixed;
                background-color: $white;
                width: 100%;
                left: -100%;
                height: 100vh;
                transition: left 0.3s ease-in;
                &.active {
                    left: 0;
                }
                .nav-list {
                    float: left;
                    width: 40%;
                    padding: 0 20px 0;
                }
                #contact-nav {
                    display: block;
                }
            }
        }
        .minicart-wrapper {
            padding: 5px 0;
            margin-top: 0;
            margin-right: 5px;
            .showcart {
                // left: 100px;
                top: 5px;
                right: 25px;
                left: auto;
            }
            &.active {
                display: none;
                .showcart {
                    &:before {
                    }
                }
            }
        }
    }
  }
  @include media($mobile-down) {
    .content {
        // height: 60px;
        .wrap {
            .brand {
                .nav-toggle {
                    // top: 54px;
                }
            }
            .site-nav {
                // margin: 50px 0 0;
                .nav-list {
                  li {
                    a {
                      font-size: 26px;
                    }
                  }
                }
                .nav-list, #contact-nav {
                    width: 100%;
                }
                #contact-nav {
                    padding: 30px 20px 120px;
                    font-size: 20px;
                    margin-bottom: 120px;
                }

            }
        }
        .minicart-wrapper {
            // padding: 0;
            .showcart {
                .counter-price {
                    display: none;
                }
            }
        }
    }
  }
  @include media($mobile-small-down) {
    .content {

        .wrap {
            .logo {
                width: 150px;
                // margin: 0 0 10px 20px;
            }
            .site-nav {
                // left: -100%;
                // width: 100%;
            }
        }
        .minicart-wrapper {
            // padding: 0;
            .showcart {
                .counter-price {
                    display: none;
                }
            }
        }
    }
  }
    $nav-text: #191919;
    .minicart-wrapper {
        float: right;
        display: inline-block;
        width: 33%;
        padding: 10px 0;
        color: rgba($nav-text, 0.7);
        .showcart {
        // NON-ECOMMERCE
        pointer-events: none;
        // REMOVE LATER
          position: relative;
          display: block;
          background: none;
          height: 100%;
          left: 200px;
          top: 10px;
          &:before {
            content: '' !important;
            position: relative;
            right: -15px;
            width: 30px;
            height: 30px;
            z-index: 8;
            display: inline-block;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('../images/icons/cart.svg');
            font-family: none!important;
          }
            .counter {
                position: relative;
                border-radius: 40px !important;
                width: 24px;
                bottom: -5px;
                z-index: 9999;
                background-color: $tertiary !important;
            }
            .counter-price {
                @include vertical-align(relative);
                // REMOVE FOR NON-ECOMMERCE DEPLOYMENT
                display: none;
                span {
                    display:inline-block;
                    color: $textcolor !important;
                    position: relative;
                    bottom: 5px;
                }
            }
        }
      }
}


.panel.header {
    .links,
    .switcher {
        // display: none;
    }
}

.nav-sections {
    background: $navigation__background;
}

.nav-toggle {
    @include lib-icon-font(
        $icon-menu,
        $_icon-font-size       : 28px,
        $_icon-font-color      : $header-icons-color,
        $_icon-font-color-hover: $header-icons-color-hover
    );
    @include lib-icon-text-hide();
    cursor: pointer;
    display: block;
    font-size: 0;
    left: 15px;
    position: absolute;
    top: 15px;
    z-index: 14;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m - 1) {
    .navigation {
        padding: 0;

        .parent {
            .level-top {
                position: relative;
                @include lib-icon-font(
                    $_icon-font-content : $icon-down,
                    $_icon-font-size    : 42px,
                    $_icon-font-position: after,
                    $_icon-font-display : block
                );

                &:after {
                    position: absolute;
                    right: 7px;
                    top: -8px;
                }

                &.ui-state-active {
                    @include lib-icon-font-symbol(
                        $_icon-font-content : $icon-up,
                        $_icon-font-position: after
                    );
                }
            }
        }
    }

    .nav-sections {
        -webkit-overflow-scrolling: touch;
        transition: left 0.3s;
        height: 100%;
        left: -80%;
        left: calc(-1 * (100% - #{$active-nav-indent}));
        overflow: auto;
        position: fixed;
        top: 0;
        width: 80%;
        width: calc(100% - #{$active-nav-indent});

        .switcher {
            border-top: 1px solid $color-gray82;
            font-size: 1.6rem;
            font-weight: 700;
            margin: 0;
            padding: 0.8rem 3.5rem 0.8rem 2rem;

            .label {
                display: block;
                margin-bottom: $indent__xs;
            }

            &:last-child {
                border-bottom: 1px solid $color-gray82;
            }
        }

        .switcher-trigger {
            strong {
                position: relative;
                @include lib-icon-font(
                    $_icon-font-content : $icon-down,
                    $_icon-font-size    : 42px,
                    $_icon-font-position: after,
                    $_icon-font-display : block
                );

                &:after {
                    position: absolute;
                    right: -40px;
                    top: -16px;
                }
            }

            &.active strong {
                @include lib-icon-font-symbol(
                    $_icon-font-content : $icon-up,
                    $_icon-font-position: after
                );
            }
        }

        .switcher-dropdown {
            @include lib-list-reset-styles();
            padding: $indent__s 0;
        }

        .header.links {
            @include lib-list-reset-styles();
            border-bottom: 1px solid $color-gray82;

            li {
                font-size: 1.6rem;
                margin: 0;

                &.greet.welcome {
                    border-top: 1px solid $color-gray82;
                    font-weight: 700;
                    padding: 0.8rem $indent__base;
                }

                & > a {
                    border-top: 1px solid $color-gray82;
                }
            }

            a,
            a:hover {
                color: $navigation-level0-item__color;
                text-decoration: $navigation-level0-item__text-decoration;
                display: block;
                font-weight: 700;
                padding: 0.8rem $indent__base;
            }

            .header.links {
                border: 0;
            }
        }
    }

    .nav-before-open {
        height: 100%;
        overflow-x: hidden;
        width: 100%;

        .page-wrapper {
            transition: left 0.3s;
            height: 100%;
            left: 0;
            overflow: hidden;
            position: relative;
        }

        body {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;
        }
    }

    .nav-open {
        .page-wrapper {
            left: 80%;
            left: calc(100% - #{$active-nav-indent});
        }

        .nav-sections {
            $_shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);

            box-shadow: $_shadow;
            left: 0;
            z-index: 99;
        }

        .nav-toggle {
            &:after {
                background: rgba(0, 0, 0, 0.7);
                content: '';
                display: block;
                height: 100%;
                position: fixed;
                right: 0;
                top: 0;
                width: 100%;
                z-index: 1;
            }
        }
    }

    .nav-sections-items {
        @include lib-clearfix();
        position: relative;
        z-index: 1;
    }

    .nav-sections-item-title {
        background: darken($navigation__background, 5%);
        border: solid darken($navigation__background, 10%);
        border-width: 0 0 1px 1px;
        box-sizing: border-box;
        float: left;
        height: 71px;
        padding-top: 24px;
        text-align: center;
        width: 33.33%;

        &.active {
            background: transparent;
            border-bottom: 0;
        }

        .nav-sections-item-switch {
            &:hover {
                text-decoration: none;
            }
        }
    }

    .nav-sections-item-content {
        @include lib-clearfix();
        box-sizing: border-box;
        float: right;
        margin-left: -100%;
        margin-top: 71px;
        width: 100%;

        &.active {
            display: block;
        }

        padding: $indent__m 0;
    }

    @include lib-main-navigation();
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .nav-toggle {
        display: none;
    }

    .nav-sections {
        flex-shrink: 0;
        flex-basis: auto;
        margin-bottom: $indent__m;
    }

    .nav-sections-item-title {
        display: none;
    }

    .nav-sections-item-content {
        display: block !important;
    }

    .nav-sections-item-content > * {
        display: none;
    }

    .nav-sections-item-content {
        > .navigation {
            display: block;
        }
    }

    @include lib-main-navigation-desktop();

    .panel.header {
        .links,
        .switcher {
            display: inline-block;
        }
    }
}
