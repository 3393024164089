
#partners {
  // margin-bottom: $section-divider;
  @include media($tablet-down) {
    margin-bottom: 0;
  }
  .wrap {
    padding: 50px;
    @include media($tablet-down) {
      padding: 20px 30px;
    }
    // box-shadow: $box-shadow;
  }
}

.partners-heading {
  color: $tertiary;
  font-weight: bold;
  margin: 30px 0px;
  @include media($tablet-down) {
    font-size: 30px;
  }
}

.partner-group {
  margin-bottom: 20px;

  .label {
    color: $secondary;
    font-weight: bold;
    border-bottom: 1px solid $divider;
  }

  .logo-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include media($tablet-down) {
      display: block;
    }

    .logo-tile {
      width: 25%;
      padding: 10px;
      margin: 0 auto;

      @include media($tablet-down) {

      }

      .logo-img {
        margin: 0 auto;
        height: 130px;
        width: 130px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    &:nth-child(2){
      .logo-group {
        .logo {
          .logo-img {
            height: 100px;
            width: 100px;
          }
        }
      }
    }
  }
}
