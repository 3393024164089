.call-to-action {
  padding: $section-divider 0;
  @include media($tablet-down) {
    padding: 0;
  }

  &.gray-theme {
    background: $background-tertiary;
  }
  &.white-theme {
    background: $white;
  }

  &.text-columns {
    .wrap {
      .container {
        display: flex;
        flex-direction: row;
        .image {
          padding: 10px 0 0 ;
          flex: 2;
        }
        .text-block {
          padding: 0 100px 10px 0px;
          flex: 1;
        }
        @include media($tablet-down) {
          padding: 20px 0 0;
          flex-direction: column;
          .image, .text-block {
            padding: 30px;
          }
          .image {
            height: auto;
          }
        }
      }
    }
  }

  .wrap {
    // box-shadow: $box-shadow;
    @include media($tablet-down) {
      padding: 0px;
    }

    .container {
      display: flex;
      margin: 20px 60px;

      @include media($tablet-down) {
        margin: 0px;
      }

      &.image-right {
        flex-direction: row-reverse;
        @include media($tablet-down) {
          flex-direction: column;
        }
      }

      &.image-left {
        flex-direction: row;
        .text-block {
          padding: 10px 0px 10px 100px;
        }

        @include media($tablet-down) {
          flex-direction: column;
        }
      }

      .image {
        flex: 1;
        // height: $grid-item-height;
        // background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        @include media($tablet-down) {
          width: 100%;
          min-height: $grid-item-height / 2;
        }
        @include media($mobile-down) {
          min-height: $grid-item-height / 2;
        }
      }

      .text-block {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        padding: 10px 100px 10px 0px;

        @include media($tablet-down) {
          width: 100%;
          flex-basis: auto;
          padding: 30px !important;
        }

        h1,h2,h3,h4,h5,h6,.title {
          font-family: $heading-bold;
          color: $tertiary;
          margin-bottom: 20px;
        }
        .tag {
          display: inline-block;
          padding: 4px 50px 4px 20px;
          margin: 0 0 25px;
          // background-color: $secondary;
          border-radius: 5px;
          font-family: $heading-family__base;
          color: #FFFFFF;
          background-image: url('../images/label_bg.svg');
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          a {
            font-size: 14px;
            text-transform: uppercase;
            color: $white;
            text-decoration: none;
          }
        }

        // @include media($mobile-down) {
        //   .title {
        //       font-family: $heading-family__base;
        //   }
        // }

        .bulletpoints {
          li {
            color: $tertiary;
            span {
              color: $primary;
            }
          }
        }

        p:first-of-type {
          font-size: 16px;
          color: $secondary-lighter;
          font-weight: $medium;

          @include media($mobile-down) {
            font-size: 16px;
          }
        }

        .more-button {
          margin: 20px 0px;

          @include media($tablet-down) {
            margin: 20px 0;
          }

          &::before {
            content: '';
            display: inline-block;
            height: 40px;
            width: 40px;
            background-image: url('../images/MoreButton.svg');
            background-position: center;
            background-repeat: repeat;
            background-size: contain;
            margin-right: 10px;
          }

          span {
            font-family: $heading-bold;
            text-transform: uppercase;
            font-size: 18px;
            color: $secondary;
            height: 40px;
            line-height: 40px;
            vertical-align: top;
            transition: 0.3s ease;
          }

          &:hover {
            text-decoration: none;
            cursor: pointer;
            span {
              color: $tertiary;
            }
          }
        }
      }
    }
  }
}
