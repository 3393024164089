.post-pagination {
  background: $background-tertiary;
  padding: 40px 0;
  .wrap {
    text-align: center;
    a {
      display: inline-block;
      padding: 0 20px;
      i {
          font-size: 30px;
           border-radius: 50%;
            border: 1px solid #8a3159;
            padding : 0 0px 5px 5px;
            color: #8a3159;
            width: 70px;
            height: 70px;
            /* text-align: center; */
            line-height: 70px;
      }
      .fa-chevron-left:before {
          padding-right: 5px;
      }
     h4{
        font-size: 19px; 
        color: #1b416e;
     }
     &:hover {
            text-decoration: none;
        }
    }
  }
}