a.action.primary,
button {
    border-radius: 3px;
}
button {
    &:active {
        box-shadow: $button__shadow;
    }
}
a.action.primary {
    @include lib-link-as-button();
}
.action.primary {
    background: $secondary!important;
    border: 1px solid $secondary!important;
    @include lib-button-primary();
    &:hover {
      background: $tertiary!important;
      border: 1px solid $tertiary!important;
    }
}
