// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Responsive variables
//  _____________________________________________

$use-flex: true !default; // Use flexbox [true|false]
$responsive: true !default; // Theme is responsive [true|false]

$screen__xxs: 320px !default;
$screen__xs: 480px !default;
$screen__s: 640px !default;
$screen__m: 768px !default;
$screen__l: 1024px !default;
$screen__xl: 1440px !default;

/*=============================================>>>>>
= Grid =
===============================================>>>>>*/

// ----- Default grid settings ----- //

$max-width:           1440px;
$grid-columns:        12;
$gap:                 20px;
$default-feature:     max-width;


// ----- Define breakpoints ----- //

$larger-desktop-breakpoint: 1980px;
$large-desktop-breakpoint:  1600px;
$wrapper-breakpoint:        1440px;
$desktop-breakpoint:        1300px;
$tablet-breakpoint:         1080px;
$tablet-small-breakpoint:   960px;
$medium-breakpoint:         800px;
$mobile-breakpoint:         750px;
$mobile-small-breakpoint:   520px;

$larger-desktop-down:         new-breakpoint(max-width $larger-desktop-breakpoint 12);
$large-desktop-down:        new-breakpoint(max-width ($large-desktop-breakpoint) 12);
$wrapper-down:              new-breakpoint(max-width ($wrapper-breakpoint) 12);
$desktop-down:              new-breakpoint(max-width ($desktop-breakpoint) 12);
$tablet-down:               new-breakpoint(max-width ($tablet-breakpoint) 12);
$tablet-small-down:         new-breakpoint(max-width ($tablet-small-breakpoint) 12);
$medium-down:               new-breakpoint(max-width ($medium-breakpoint) 12);
$mobile-down:               new-breakpoint(max-width ($mobile-breakpoint) 4);
$mobile-small-down:         new-breakpoint(max-width ($mobile-small-breakpoint) 4);

$larger-desktop-up:         new-breakpoint(min-width $larger-desktop-breakpoint 12);
$large-desktop-up:          new-breakpoint(min-width $large-desktop-breakpoint 12);
$wrapper-up:                new-breakpoint(min-width ($wrapper-breakpoint) 12);
$desktop-up:                new-breakpoint(min-width $desktop-breakpoint 12);
$tablet-up:                 new-breakpoint(min-width $tablet-breakpoint 12);
$tablet-small-up:           new-breakpoint(min-width $tablet-small-breakpoint 12);
$mobile-up:                 new-breakpoint(min-width $mobile-breakpoint 12);
$mobile-small-up:           new-breakpoint(min-width $mobile-small-breakpoint 12);
