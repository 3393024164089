.fotorama {
    min-width: 1px;
    overflow: hidden;
    &--fullscreen {
        @extend .fotorama-gpu;
        position: fixed !important;
        top: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        right: 0 !important;
        width: 100% !important;
        margin: 0 !important;
        background: $color-white;
        z-index: $z-index-10 !important;
        //float: none !important;
        .fotorama__stage {
          border: none!important;
        }
    }

    &--hidden {
        @extend .fotorama-hidden;
    }
}
