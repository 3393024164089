.message.info {
    @include lib-message-icon-inner(info);
}

.message.error {
    @include lib-message-icon-inner(error);
}

.message.warning {
    @include lib-message-icon-inner(warning);
}

.message.notice {
    @include lib-message-icon-inner(notice);
}

.message.success {
    @include lib-message-icon-inner(success);
    max-width: $max-width;
    margin: 0 auto 30px;
    padding: 25px 40px;
    font-size: 16px;
    > *:first-child:before {
        top: 35px;
    }

}
.message.notice {
    font-size : 16px;
}
.products .wrap .empty {
    display :none;
}