$toolbar-mode-icon-font-size: 24px !default;
$toolbar-element-background : $panel__background-color !default;

.page-products {
    .columns {
        position: relative;
        z-index: 1;
    }
}

.toolbar {
    @extend .abs-add-clearfix;
}

.toolbar-amount {
    display: block;
    line-height: $toolbar-mode-icon-font-size + 2;
    margin: 0;
    padding: 8px 0 0;

    .products.wrapper ~ .toolbar & {
        // display: none;
    }
}
.toptoolbar-container {
  width: 100%;
  max-width: $max-width;
  margin: 20px auto 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 40px;
  @include media($mobile-down) {
    justify-content: center;
    margin: 0 auto;
    flex-direction: column;
  }
  .block-search {
      margin-right: 25px;
      @include media($mobile-down) {
        margin-right: 0;
        margin-bottom: 20px;
      }

      .label {
          display: none;
      }

      .control {
          padding: 0!important;
          input {
              height: 50px;
              border: 2px solid $secondary;
              border-radius: 5px;
              font-size: 14px;
              padding-left: 12px;
              font-family: $font-family-base;
              color: $textcolor;
              left: auto;
              position: relative;
              margin: 0;
              &::placeholder {
                font-size: 14px;
                color: $textcolor;
              }
          }
      }
  }
  .brochure-download, .filter-by {
    display: inline-block;
    padding: 10px 15px;
    border: 2px solid $secondary;
    border-radius: 5px;
    font-weight: bold;
    text-transform: uppercase;
    color: $secondary;
    text-decoration: none;
    font-size: 14px;
    transition: all .3s ease;
    &:hover {
        background: $secondary;
        color: $white;
    }
  }
  .toolbar-products {
    &:nth-child(1){
      .toolbar-amount {
        display: none;
      }
        .pages {
            display: block!important;
            padding: 0!important;
        }
    }
  }
}
.bottomtoolbar-container {
    width: 100%;
    padding: 0 0 60px;
    max-width: $max-width;
    margin: 0 auto;
    .toolbar-products {
    &:nth-child(1){
        .pages {
            display: block!important;
            padding: 0!important;
            @include media($mobile-down) {
              margin-top: 20px;
            }
        }
        .toolbar-amount {
            // padding: 0 30px 0;
            font-size: 14px;
            .toolbar-number {
                font-weight: bold;
            }
        }
    }
  }
}


.toolbar-products {
    @extend .abs-add-clearfix;
    // margin-bottom: $indent__xl;
    // padding: 0 $indent__s;
    padding: 0 40px;
    text-align: center;
    margin: 0 auto;
    max-width: 1440px;
    @include media($mobile-down) {
      padding: 0 30px;
    }

    .pages {
        display: none;

        .products.wrapper ~ & {
            display: block;
        }
    }

    .limiter {
        display: none;

        .control {
            display: inline-block;
        }
    }
}

.sorter {
    padding: 4px 0 0;

    .products.wrapper ~ .toolbar & {
        display: none;
    }
}

.sorter-options {
    margin: 0 0 0 7px;
    width: auto;
}

.sorter-action {
    vertical-align: top;
    @include lib-icon-font(
        $icon-arrow-up,
        $_icon-font-size       : 28px,
        $_icon-font-line-height: 32px,
        $_icon-font-color      : $header-icons-color,
        $_icon-font-color-hover: $header-icons-color-hover,
        $_icon-font-text-hide  : true
    );
}

.sorter.sort-desc {
    &:before {
        content: $icon-arrow-down;
    }
}

.modes {
    display: none;
}

.limiter-options {
    margin: 0 5px 0 7px;
    width: auto;
}

.limiter-label {
    font-weight: 400;
}

.limiter {
    .page-products .toolbar & {
        display: none;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-products {
        .columns {
            padding-top: 0;
            position: relative;
            z-index: 1;
        }
    }

    .toolbar {
        .products.wrapper ~ & .pages {
            float: left;
        }
    }

    .toolbar-amount {
        float: left;
    }

    .sorter {
        float: right;
    }

    .modes {
        // display: block;
        float: left;
        margin-right: $indent__base;

        .products.wrapper ~ .toolbar & {
            display: none;
        }
    }

    .modes-label {
        @extend .abs-visually-hidden-desktop;
    }

    .modes-mode {
        color: $text__color__muted;
        border: 1px solid $border-color__base;
        border-right: 0;
        float: left;
        font-weight: $font-weight__regular;
        line-height: 1;
        padding: 7px 10px;
        text-align: center;
        @include lib-icon-font(
            $icon-grid,
            $_icon-font-size       : $toolbar-mode-icon-font-size,
            $_icon-font-text-hide  : true,
            $_icon-font-color      : $text__color__muted,
            $_icon-font-color-hover: $text__color__muted,
            $_icon-font-display    : block
        );

        &:not(.active) {
            &:hover {
                color: $text__color__muted;
                background: darken($toolbar-element-background, 7%);
            }
        }

        &:last-child {
            border-right: 1px solid $border-color__base;
        }

        &.active {
            color: $primary__color__light;
        }
    }

    .mode-list {
        @include lib-icon-font-symbol($icon-list);
    }

    .limiter {
        float: right;

        .products.wrapper ~ .toolbar & {
            display: block;
        }
    }
}
