.cart {
    &-summary {
        .block {
            &.giftcard {
                .action.check {
                    @extend .abs-action-button-as-link;
                    @include lib-button-s();
                    margin-top: $indent__base;
                }
            }
        }
    }
}

.page-product-giftcard {
    .giftcard-amount {
        margin-bottom: 0;
        text-align: left;

        .field:last-child {
            padding-bottom: $form-field__vertical-indent;
        }
    }

    .product-options-wrapper .field:first-of-type {
        margin-top: $form-field__vertical-indent;
    }

    .fieldset.giftcard {
        & > .legend {
            @extend .abs-visually-hidden;
            & + br {
                @extend .abs-no-display;
            }
        }
    }
}
