//
//  Indents
//  ---------------------------------------------

$indent__base: 20px !default;
$indent__xl  : 40px !default;
$indent__l   : 30px !default;
$indent__m   : 25px !default;
$indent__s   : 10px !default;
$indent__xs  : 5px !default;
$indent__auto: auto !default;

//
//  Icons
//  ---------------------------------------------
$icons__font-name: 'icons-blank-theme' !default;
