.column {
    .block-addbysku {
        .fieldset {
            margin: 0;
            .fields {
                position: relative;
                .field {
                    display: inline-block;
                    margin-bottom: $indent__s;
                    vertical-align: top;
                }
                .actions-toolbar {
                    position: absolute;
                    right: 0;
                    top: 4px;
                }
            }
        }
        .sku {
            @extend .abs-add-box-sizing;
            margin-right: -155px;
            padding-right: 160px;
            width: 100%;
        }
        .skus {
            > .label {
                margin-top: $indent__s;
            }
        }
        .qty {
            .qty {
                @extend .abs-input-qty;
                + .mage-error {
                    width: 80px;
                }
            }
        }
        .block-content {
            @extend .abs-add-clearfix-desktop;
            .box {
                @extend .abs-blocks-2columns;
            }
            .actions-toolbar {
                clear: both;
                @include lib-actions-toolbar(
                    $_actions-toolbar-actions-position: left,
                    $_actions-toolbar-margin          : 0 0 $indent__base
                );
                @include lib-actions-toolbar-clear-floats();
                > .secondary {
                    text-align: left;
                }
            }
        }
        .reset {
            margin: $indent__s 0;
        }
        .note {
            display: block;
            margin: 0 0 $indent__base;
        }
    }
}
.block-cart-failed {
    @extend .abs-shopping-cart-items;
    margin-top: $indent__xl;
    .block-title {
        font-size: headings($heading-h2, font-size);
        strong {
            font-weight: headings($heading-h2, font-weight);
        }
    }
    .actions {
        text-align: center;
        .action {
            margin-bottom: $indent__s;
        }
    }
}
//
//  Mobile
//  _____________________________________________
@include max-screen($screen__s) {
    .column {
        .block-addbysku {
            .sku {
                margin-right: -85px;
                padding-right: 90px;
            }
            .action {
                &.remove {
                    @include lib-button-icon(
                        $_icon-font-content  : $icon-remove,
                        $_icon-font-text-hide: true,
                        $_icon-font-size     : 28px,
                        $_icon-font-margin   : 1px 0 0
                    );
                    @include lib-button-reset();
                }
            }
        }
    }
}
@include min-screen($screen__s) {
    .column {
        .block-addbysku {
            .action {
                &.remove {
                    @include lib-button-reset();
                    @include lib-button-as-link();
                }
            }
        }
    }
}
//
//  Desktop
//  _____________________________________________
@include min-screen($screen__m) {
    .block-cart-failed {
        @extend .abs-shopping-cart-items-desktop;
        .actions {
            text-align: left;
        }
        .actions.primary {
            float: right;
        }
        .block-content {
            @extend .abs-add-clearfix-desktop;
        }
    }
}
