//
//  Variables
//  ---------------------------------------------

$product-name-link__color                   : $text__color !default;
$product-name-link__color__active           : $text__color !default;
$product-name-link__color__hover            : $text__color !default;
$product-name-link__color__visited          : $text__color !default;

$product-name-link__text-decoration         : none !default;
$product-name-link__text-decoration__active : $link__hover__text-decoration !default;
$product-name-link__text-decoration__hover  : $link__hover__text-decoration !default;
$product-name-link__text-decoration__visited: $link__hover__text-decoration !default;

//
//  Product Lists
//  _____________________________________________

.products {
    width: 100%;
    margin: $indent__l 0 0;
    clear: both;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &.products-search {
        padding-bottom: 70px!important;
        display: block!important;
    }
}

.product {
    &-items {
        @extend .abs-reset-list;
        padding: 0 30px 0;
        @include media($mobile-down) {
          padding: 0 20px 0;
        }
    }

    &-item {
        @extend .abs-add-box-sizing;
        flex-grow: 0;
        margin: 0 10px 15px 0!important;
        width: calc(25% - (30px / 4));
        @include media($tablet-up) {
            &:nth-child(4n) {
                margin-right: 0!important;
            }
        }
        @include media($tablet-down) {
            width: calc(33.3333% - (20px / 3));
            &:nth-child(4n) {
                margin-right: 10px!important;
            }
            &:nth-child(3n) {
                margin-right: 0!important;
            }
        }
        @include media($medium-down) {
            width: calc(50% - 5px);
            &:nth-child(3n) {
                margin-right: 10px!important;
            }
            &:nth-child(2n) {
                margin-right: 0!important;
            }
        }
        @include media($mobile-down) {
            width: 100%;
            margin: 0 0 15px 0!important;
            &:nth-child(3n) {
                margin-right: 0!important;
            }
        }

        .products-grid & {
            display: inline-block;
        }

        &-name {
            flex-grow: 1;
            @extend .abs-product-link;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            hyphens: auto;
            padding: 12px 10px;
            background-color: $secondary;
            text-align: center;
            padding: 15px 20px;
            .product-item-link {
                color: $white;
                font-size: 14px;
                font-family: $font-medium;
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                    color: $white;
                }
                &:visited {
                    color: $white;
                }
            }
            a {
                color: $white;
                font-size: 14px;
                font-family: $font-medium;
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                    color: $white;
                }
            }
        }

        &-info {
            max-width: 100%;
            border: 1px solid #DDD;
            height: 100%;
            display: flex;
            // margin: 0 10px 0 0;
            flex-direction: column;
            @include media($mobile-small-down) {
              margin: 0!important;
            }

            .page-products & {
                // @include grid();
            }
        }

        &-actions {
            display: none;

            .actions-secondary {
                & > .action {
                    @extend .abs-actions-addto-gridlist;
                    &:before {
                        margin: 0;
                    }

                    span {
                        @extend .abs-visually-hidden;
                    }
                }
            }
        }

        &-description {
            margin: $indent__m 0;
        }

        &-details {
            display: block;
            position: relative;
            background-color: $background-grid;
            text-align: center;
            border-top: 1px solid #DDD;
            &:hover {
              &:after {
                transform: translateY(-50%) rotate(90deg);
              }
            }
            &:after {
              content: '';
              position: absolute;
              width: 25px;
              height: 25px;
              background-image: url('../images/icons/cog.svg');
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              right: 20px;
              top: 50%;
              transform: translateY(-50%);
              transition: transform .3s ease;
            }
            .product-item-actions {
                margin: 0!important;
            }
            a {
                color: $secondary;
                text-transform: uppercase;
                font-weight: $font-weight__bold;
                padding: 12px 20px;
                display: block;
                text-decoration: none;
            }
        }

        &-photo {
            // flex-grow: 1;
            // display: flex;
            .product-image-container {
                position: relative;
                display: block;
                cursor: pointer;
                background: $white;
                display: flex;
                align-items: center;
                min-height: 100%;
                min-width: 100%;
                .product-image-wrapper {
                    display: block;
                    width: 100%;
                    padding: 0 30px 100%;
                    background-color: $white;
                    img {
                        max-width: 100%;
                        width: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        // right: auto;
                        // bottom: auto;
                        height: 100%;
                        object-fit: contain;
                        // @include media($tablet-down) {
                        //     height: 350px;
                        // }
                    }
                }
            }
        }

        .product-reviews-summary {
            .rating-summary {
                margin: 0 4px 0 0;
            }

            .reviews-actions {
                font-size: $font-size__s;
                margin-top: 5px;
                text-transform: lowercase;
            }
        }

        .price-box {
            margin: $indent__s 0 $indent__m;

            .price {
                font-size: 14px;
                font-weight: $font-weight__bold;
                white-space: nowrap;
            }

            .price-label {
                font-size: $font-size__s;

                &:after {
                    content: ':';
                }
            }
        }

        .special-price,
        .minimal-price {
            .price {
                font-size: 14px;
                font-weight: $font-weight__bold;
            }

            .price-wrapper {
                display: inline-block;
            }

            .price-including-tax + .price-excluding-tax {
                display: block;
            }
        }

        .special-price {
            display: block;
        }

        .old-price {
            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price {
            .price-container {
                display: block;
            }
        }

        .minimal-price-link {
            margin-top: 5px;

            .price-label {
                color: $link__color;
                font-size: 14px;
            }

            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price-link,
        .price-excluding-tax,
        .price-including-tax {
            display: block;
            white-space: nowrap;
        }

        .price-from,
        .price-to {
            margin: 0;
        }

        .action.tocompare {
            @include lib-icon-font-symbol($icon-compare-empty);
        }

        .tocart {
            white-space: nowrap;
        }
    }
}

.column.main {
    .product {
        &-items {
            // margin-left: -$indent__base;
        }

        &-item {
            // padding-left: $indent__base;
        }
    }

}

.price-container {
    .price {
        font-size: 14px;
    }

    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }

    .price-including-tax + .price-excluding-tax,
    .weee,
    .price-including-tax + .price-excluding-tax .price,
    .weee .price,
    .weee + .price-excluding-tax:before,
    .weee + .price-excluding-tax .price {
        font-size: 11px;
    }

    .weee {
        &:before {
            content: '('attr(data-label) ': ';
        }

        &:after {
            content: ')';
        }

        + .price-excluding-tax {
            &:before {
                content: attr(data-label) ': ';
            }
        }
    }
}

.products-list {
    .product {
        &-item {
            display: table;
            width: 100%;

            &-info {
                display: table-row;
            }

            &-photo {
                display: table-cell;
                padding: 0 $indent__l $indent__l 0;
                vertical-align: top;
                width: 1%;
            }

            &-details {
                display: table-cell;
                vertical-align: top;
            }
        }
    }

    .product-image-wrapper {
        // @extend .abs-reset-image-wrapper;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .products-list .product {
        &-item {
            table-layout: fixed;

            &-photo {
                padding: 0 $indent__s $indent__s 0;
                width: 30%;
            }
        }
    }
}

@include min-screen($screen__s) {
    .product {
        &-item {
            .products-grid & {
                margin-bottom: $indent__l;
            }

            &-actions {
                display: block;

                .products-grid & {
                    margin: $indent__s 0;
                }

                .actions-primary + .actions-secondary {
                    display: table-cell;
                    padding-left: 5px;
                    white-space: nowrap;
                    width: 50%;
                    & > * {
                        white-space: normal;
                    }
                }

                .actions-primary {
                    display: table-cell;
                }
            }
        }
    }

    // .products-grid .product-item {
    //     width: 33.3333%;
    // }

    // .page-products,
    // .page-layout-1column,
    // .page-layout-3columns,
    // .page-products.page-layout-1column,
    // .page-products.page-layout-3columns {
    //     .products-grid {
    //         .product-item {
    //             width: 33.3333%;
    //         }
    //     }
    // }
}

//
//  Desktop
//  _____________________________________________

// @include min-screen($screen__m) {
//     .page-products {
//         .products-grid {
//             .product-item {
//                 width: calc(33.3333% - (20px / 3));
//             }
//         }
//     }

//     .page-products.page-layout-1column {
//         .products-grid {
//             .product-item {
//                 width: calc(25% - (30px / 4));
//             }
//         }
//     }

//     .page-products.page-layout-3columns {
//         .products-grid {
//             .product-item {
//                 width: calc(50% - 10px);
//             }
//         }
//     }
// }
// @include min-screen($screen__l) {
//     .products-grid {
//         .product-item {
//             width: calc(25% - (30px / 4));
//         }
//     }

//     .page-layout-1column {
//         .products-grid {
//             .product-item {
//                 width: calc(16.6666% - (50px / 6));
//             }
//         }
//     }

//     .page-layout-3columns {
//         .products-grid {
//             .product-item {
//                 width: calc(25% - (30px / 4));
//             }
//         }
//     }

    // .page-products {
    //     .products-grid {
    //         .product-items {
    //             margin: 0;
    //         }

    //         .product-item {
    //             margin-left: calc((100% - 4 * 23.233%) / 3);
    //             padding: 0;
    //             width: 23.233%;

    //             &:nth-child(4n + 1) {
    //                 margin-left: 0;
    //             }
    //         }
    //     }
    // }

    // .page-products {
    //     &.page-layout-1column {
    //         .products-grid {
    //             .product-item {
    //                 margin-left: 0;
    //                 width: 25%;

    //                 @include media($tablet-down) {
    //                     width: 33.333333333%;
    //                 }
    //                 @include media($mobile-down) {
    //                     width: 50%;
    //                 }
    //                 @include media($mobile-small-down) {
    //                     width: 100%;
    //                 }
    //             }
    //         }
    //     }

    //     &.page-layout-3columns {
    //         .products-grid {
    //             .product-item {
    //                 margin-left: 1%;
    //                 width: 32.667%;

    //                 &:nth-child(3n) {
    //                     margin-left: 1%;
    //                 }

    //                 &:nth-child(3n + 1) {
    //                     margin-left: 0;
    //                 }
    //             }
    //         }
    //     }
    // }
// }

//  .page-products {
//         &.page-layout-1column {
//             .products-grid {
//                 .product-item {

//                     @include media($tablet-down) {
//                         width: 33.333333333%;
//                     }
//                     @include media($medium-down) {
//                         width: 50%;
//                     }
//                     @include media($mobile-down) {
//                         width: 50%;
//                         margin-bottom: 15px;
//                         &-name {
//                             a {
//                                 font-size: 18px;
//                             }
//                         }
//                         &-details {
//                             a {
//                                 font-size: 18px;
//                             }
//                         }
//                     }
//                     @include media($mobile-small-down) {
//                         width: 100%;
//                     }
//                 }
//             }
//         }
//  }

 .products {
   @include media($medium-down) {
        margin-top: 10px;
    }
 }
