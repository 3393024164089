.wordpress-post-view.page-layout-blog {
  #maincontent {
    padding: 90px 40px 0;
    width: 100%;
    max-width: 900px;
    min-height: 70vh;
    margin: 0 auto;
    @include media($tablet-down) {
      padding: 70px 20px 0;
    }
    @include media($mobile-down) {
      padding: 100px 20px 0;
    }
    .post-image {
      float: none;
      margin: 0 0 20px;
      > a {
        width: 100%;
        img {
          width: 100%;
          max-height: 300px;
          object-fit: cover;
          object-position: center;
        }
      }
    }
    a {
      color: $secondary;
      text-decoration: underline;
    }
  }
}
.wordpress-post-view{
    .post-entry-details{
      display: block;
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
      padding: 69px 10px;
      .post-name{
          display: flex;
        //   margin: 20px 60px;
          margin: 1% 5%;
          @include media($mobile-down) {
            //  margin: 27px 31px;
            margin: 1% 8%;
           }
          a{
            //  margin-bottom: 20px;
             color: $tertiary;
             font-family: "dosisbold", "Helvetica Neue", Helvetica, Arial, sans-serif;
          }
      }
       .post-date{
          display: flex;
        //   margin: 19px 60px;
          margin: 1% 5%;
          @include media($mobile-down) {
            //  margin: 27px 31px;
            margin: 8% 8%;
           }
       }
       .post-image-data{
           display: flex;
        //   margin: 61px 111px;
           margin: 0 6%;
           padding-bottom: 94px;
           flex-direction: row-reverse;
           float: right;
           @include media($mobile-down) {
             margin:0;
             padding-bottom: 0;
           }
          img{
              object-fit: cover;
              width: auto;
              height: 457px;
              @include media($mobile-down) {
                 bottom:0;
                 margin:0;
                //  margin: 27px 31px;
                //  min-height: 457px;
                width:100%;
             }
          }
       }
       .post-content {
        //   margin: 21px 60px;
           margin: 1% 5%;
           display: flex;
        //   bottom: 538px;
        //   position: relative;
           flex-direction: column;
           float: left;
           width: 50%;
           @include media($mobile-down) {
             bottom:0;
            //  margin: 27px 31px;
            margin: 8% 8%;
             width: auto;
           }
       }
      }
   }