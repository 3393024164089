//
//  Variables
//  _____________________________________________

$checkout-progress-bar__font-size                             : 18px !default;
$checkout-progress-bar__font-weight                           : $font-weight__light !default;
$checkout-progress-bar__margin                                : $indent__base !default;

$checkout-progress-bar-item__background-color                 : $color-gray-middle1 !default;
$checkout-progress-bar-item__border-radius                    : 6px !default;
$checkout-progress-bar-item__color                            : $primary__color !default;
$checkout-progress-bar-item__margin                           : $indent__s !default;
$checkout-progress-bar-item__width                            : 185px !default;
$checkout-progress-bar-item__active__background-color         : $color-orange-red1 !default;
$checkout-progress-bar-item__complete__color                  : $link__color !default;

$checkout-progress-bar-item-element__width                    : 38px !default;
$checkout-progress-bar-item-element__height                   : $checkout-progress-bar-item-element__width !default;

$checkout-progress-bar-item-element-outer-radius__width       : 6px !default;
$checkout-progress-bar-item-element-inner__background-color   : $page__background-color !default;
$checkout-progress-bar-item-element-inner__color              : $checkout-progress-bar-item__color !default;
$checkout-progress-bar-item-element-inner__width              : $checkout-progress-bar-item-element__width - ($checkout-progress-bar-item-element-outer-radius__width * 2) !default;
$checkout-progress-bar-item-element-inner__height             : $checkout-progress-bar-item-element-inner__width !default;
$checkout-progress-bar-item-element-inner__active__content    : $icon-checkmark !default;
$checkout-progress-bar-item-element-inner__active__font-size  : 28px !default;
$checkout-progress-bar-item-element-inner__active__line-height: 1 !default;


//
//  Checkout Progress Bar
//  _____________________________________________

.opc-progress-bar {
    @extend .abs-reset-list;
    margin: 0 0 $checkout-progress-bar__margin;
    counter-reset: i;
    font-size: 0;
}

.opc-progress-bar-item {
    margin: 0 0 $checkout-progress-bar-item__margin;
    width: 50%;
    display: inline-block;
    position: relative;
    text-align: center;
    vertical-align: top;

    &:before { // Horizontal line
        background: $checkout-progress-bar-item__background-color;
        top: $checkout-progress-bar-item-element__width / 2;
        content: '';
        height: 7px;
        left: 0;
        position: absolute;
        width: 100%;
    }

    &:first-child {
        &:before {
            border-radius: $checkout-progress-bar-item__border-radius 0 0 $checkout-progress-bar-item__border-radius;
        }
    }

    &:last-child {
        &:before {
            border-radius: 0 $checkout-progress-bar-item__border-radius $checkout-progress-bar-item__border-radius 0;
        }
    }

    & > span {
        display: inline-block;
        padding-top: 45px;
        width: 100%;
        word-wrap: break-word;

        @include lib-typography(
            $_color      : $checkout-progress-bar-item__background-color,
            $_font-family: inherit,
            $_font-size  : $checkout-progress-bar__font-size,
            $_font-style : inherit,
            $_font-weight: $checkout-progress-bar__font-weight,
            $_line-height: inherit
        );

        &:before,
        &:after {
            background: $checkout-progress-bar-item__background-color;
            height: $checkout-progress-bar-item-element__height;
            margin-left: -($checkout-progress-bar-item-element__width / 2);
            width: $checkout-progress-bar-item-element__width;
            border-radius: 50%;
            content: '';
            left: 50%;
            position: absolute;
            top: 0;
        }

        &:after {
            background: $checkout-progress-bar-item-element-inner__background-color;
            height: $checkout-progress-bar-item-element-inner__height;
            margin-left: (-$checkout-progress-bar-item-element-inner__width / 2);
            top: $checkout-progress-bar-item-element-outer-radius__width;
            width: $checkout-progress-bar-item-element-inner__width;
            content: counter(i);
            counter-increment: i;
            @include lib-typography(
                $_color      : $checkout-progress-bar-item-element-inner__color,
                $_font-family: inherit,
                $_font-size  : $checkout-progress-bar__font-size,
                $_font-style : inherit,
                $_font-weight: $font-weight__semibold,
                $_line-height: inherit
            );
        }
    }

    &._active {
        &:before {
            background: $checkout-progress-bar-item__active__background-color;
        }

        & > span {
            color: $checkout-progress-bar-item__color;

            &:before {
                background: $checkout-progress-bar-item__active__background-color;
            }

            &:after {
                content: $checkout-progress-bar-item-element-inner__active__content;
                font-family: $icons__font-name;
                line-height: $checkout-progress-bar-item-element-inner__active__line-height;
                font-size: $checkout-progress-bar-item-element-inner__active__font-size;
            }
        }
    }

    &._complete {
        cursor: pointer;

        & > span {
            color: $checkout-progress-bar-item__color;

            &:after {
                content: $checkout-progress-bar-item-element-inner__active__content;
                font-family: $icons__font-name;
                line-height: $checkout-progress-bar-item-element-inner__active__line-height;
                font-size: $checkout-progress-bar-item-element-inner__active__font-size;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-progress-bar-item {
        width: $checkout-progress-bar-item__width;
    }
}
