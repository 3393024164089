.wordpress-homepage-view {
  #maincontent {
    padding: 40px 0 0;
    width: 100%;
    // max-width: 1440px;
    margin: 0 auto;
    // @include media($tablet-down) {
    //   padding: 70px 20px 0;
    // }
    // @include media($mobile-down) {
    //   padding: 100px 20px 0;
    // }
    .columns {
      margin-bottom: 60px;
      .column.main {
        float: none;
        width: 100%;
        .post-list-wrapper {
            width: 100%;
            max-width: 1440px;
            margin: 0 auto;
            padding: 50px 0;
          .post-list {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            > .item {
            //  width: calc((100% / 3) - (40px / 3));
              width: 100%;
            //   margin-right: 20px;
              margin-right: 2%;
              @include media($tablet-up) {
                &:nth-child(3n) {
                  margin-right: 0;
                }
              }
              @include media($tablet-down) {
                width: calc((100% / 2) - 10px);
                &:nth-child(2n) {
                  margin-right: 0;
                }
              }
              @include media($mobile-down) {
                width: 100%;
                margin-right: 0;
              }
              .post-list-formatted{
                //   margin: 20px 60px;
                margin: 1% 5%;
                  h2{
                      font-size: 22px;
                  }
              }
              .post-image-data {
                  float :left;
                  padding-right: 99px;
                // display: none;
                // float: none;
                // margin: 0;
                // > a {
                //   width: 100%;
                //   img {
                //     width: 100%;
                //     max-height: 250px;
                //     object-fit: cover;
                //     object-position: center;
                //   }
                // }
                img{
                   object-fit: cover;
                   width: auto;
                   height: 250px;
                   @include media($mobile-down) {
                     width: 100%;
                   }
                }
              }

              .post-name {
                // text-decoration: none;
                // margin-top: 10px;
                // margin-bottom: 5px;
                margin-bottom: 1%;
              }
              .post-meta {
                .post-date {
                  margin-bottom: 0;
                }
                .post-categories {
                  display: none;
                }
                .post-user {
                  display: none;
                }
              }
              a {
                color: $primary;
              }
              h2 {
                a {
                //   text-decoration: underline;
                  color: $secondary;
                }
              }
            }
          }
          .pager {
            .toolbar-amount {
              display: none;
            }
            .pages {
              padding: 20px 0;
            }
          }
        }
      }
    }
  }
}