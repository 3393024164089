.contact-index-index {
  .contact-container {
    max-width: $max-width;
    margin: 0 auto;
    display: flex;

    @include media($tablet-down) {
      flex-direction: column;
    }

    // Contact Form
    .form.contact {
      width: calc(50% - 30px);
      // background-color: $darkgrey;
      padding: 30px;
      position: relative;
      display: inline-block;
      margin-right: 40px 30px;
      @include media($tablet-down) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
      }

      .fieldset {
        margin: 0;
        .legend {
          font-family: $heading-bold;
          color: $tertiary;
          font-size: 25px;
          text-transform: uppercase;
          letter-spacing: 0.5px;
          margin-bottom: 20px;
        }
        .field {
          &.no-label {
            &:before {
              float: none;
            }
          }
          &.note {
            a {
              color: $primary;
            }
          }
        }
      }

      .actions-toolbar {
        padding: 0;
        margin: auto;

        .action.submit {
          margin: 0;
        }
      }
    }
    .google-map-iframe {
      width: 50%;
      padding: 40px 30px;
      display: inline-block;
      @include media($tablet-down) {
        width: 100%;
        padding-top: 0;
      }
      iframe {
        width: 100%;
        height: 450px;
        @include media($mobile-down) {
          height: 350px;
        }
      }
    }
  }
}
