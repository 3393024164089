// Opensans - default

// @include lib-font-face(
//     $family-name: $font-family-name__base,
//     $font-path  : '../fonts/opensans/light/opensans-300',
//     $font-weight: 300,
//     $font-style : normal
// );

// @include lib-font-face(
//     $family-name: $font-family-name__base,
//     $font-path  : '../fonts/opensans/regular/opensans-400',
//     $font-weight: 400,
//     $font-style : normal
// );

// @include lib-font-face(
//     $family-name: $font-family-name__base,
//     $font-path  : '../fonts/opensans/semibold/opensans-600',
//     $font-weight: 600,
//     $font-style : normal
// );

// @include lib-font-face(
//     $family-name: $font-family-name__base,
//     $font-path  : '../fonts/opensans/bold/opensans-700',
//     $font-weight: 700,
//     $font-style : normal
// );

$thin: 'thin';
$thinitalic: 'thinitalic';
$light: 'light';
$lightitalic: 'lightitalic';
$extralight: 'extralight';
$extralightitalic: 'extralightitalic';
$italic: 'italic';
$regular: 'regular';
$medium: 'medium';
$mediumitalic: 'mediumitalic';
$bold: 'bold';
$bolditalic: 'bolditalic';
$extrabolditalic: 'extrabolditalic';
$semibold: 'semibold';
$semibolditalic: 'semibolditalic';
$extrabold: 'extrabold';
$black: 'black';
$blackitalic: 'blackitalic';

// Montserrat - custom
$montserrat_family: "montserrat";
$montserrat__font-path: "../fonts/montserrat" !default;

@include custom-font-face(
    $family: $montserrat_family,
    $family-name: $italic,
    $font-path  : $montserrat__font-path,
    $font-style : normal
);

@include custom-font-face(
    $family: $montserrat_family,
    $family-name: $thinitalic,
    $font-path  : $montserrat__font-path,
    $font-style : normal
);

@include custom-font-face(
    $family: $montserrat_family,
    $family-name: $thin,
    $font-path  : $montserrat__font-path,
    $font-style : normal
);

@include custom-font-face(
    $family: $montserrat_family,
    $family-name: $light,
    $font-path  : $montserrat__font-path,
    $font-style : normal
);

@include custom-font-face(
    $family: $montserrat_family,
    $family-name: $lightitalic,
    $font-path  : $montserrat__font-path,
    $font-style : normal
);

@include custom-font-face(
    $family: $montserrat_family,
    $family-name: $extralight,
    $font-path  : $montserrat__font-path,
    $font-style : normal
);

@include custom-font-face(
    $family: $montserrat_family,
    $family-name: $extralightitalic,
    $font-path  : $montserrat__font-path,
    $font-style : normal
);

@include custom-font-face(
    $family: $montserrat_family,
    $family-name: $extrabolditalic,
    $font-path  : $montserrat__font-path,
    $font-style : normal
);

@include custom-font-face(
    $family: $montserrat_family,
    $family-name: $regular,
    $font-path  : $montserrat__font-path,
    $font-style : normal
);

@include custom-font-face(
    $family: $montserrat_family,
    $family-name: $medium,
    $font-path  : $montserrat__font-path,
    $font-style : normal
);

@include custom-font-face(
    $family: $montserrat_family,
    $family-name: $mediumitalic,
    $font-path  : $montserrat__font-path,
    $font-style : normal
);

@include custom-font-face(
    $family: $montserrat_family,
    $family-name: $bold,
    $font-path  : $montserrat__font-path,
    $font-style : normal
);

@include custom-font-face(
    $family: $montserrat_family,
    $family-name: $bolditalic,
    $font-path  : $montserrat__font-path,
    $font-style : normal
);

@include custom-font-face(
    $family: $montserrat_family,
    $family-name: $semibold,
    $font-path  : $montserrat__font-path,
    $font-style : normal
);

@include custom-font-face(
    $family: $montserrat_family,
    $family-name: $semibolditalic,
    $font-path  : $montserrat__font-path,
    $font-style : normal
);

@include custom-font-face(
    $family: $montserrat_family,
    $family-name: $black,
    $font-path  : $montserrat__font-path,
    $font-style : normal
);

@include custom-font-face(
    $family: $montserrat_family,
    $family-name: $blackitalic,
    $font-path  : $montserrat__font-path,
    $font-style : normal
);

// Dosis - custom
$dosis__font-path: "../fonts/dosis" !default;
$dosis_family: "dosis";

@include custom-font-face(
    $family: $dosis_family,
    $family-name: $bold,
    $font-path  : $dosis__font-path,
    $font-style : normal
);

@include custom-font-face(
    $family: $dosis_family,
    $family-name: $extrabold,
    $font-path  : $dosis__font-path,
    $font-style : normal
);

@include custom-font-face(
    $family: $dosis_family,
    $family-name: $extralight,
    $font-path  : $dosis__font-path,
    $font-style : normal
);

@include custom-font-face(
    $family: $dosis_family,
    $family-name: $light,
    $font-path  : $dosis__font-path,
    $font-style : normal
);

@include custom-font-face(
    $family: $dosis_family,
    $family-name: $medium,
    $font-path  : $dosis__font-path,
    $font-style : normal
);

@include custom-font-face(
    $family: $dosis_family,
    $family-name: $regular,
    $font-path  : $dosis__font-path,
    $font-style : normal
);

@include custom-font-face(
    $family: $dosis_family,
    $family-name: $semibold,
    $font-path  : $dosis__font-path,
    $font-style : normal
);

.items {
    @include lib-list-reset-styles();
}

//
//    Desktop
//--------------------------------------

@include min-screen($screen__m) {
    h1 {
        font-size: $h1__font-size-desktop;
        margin-bottom: $h1__margin-bottom__desktop;
    }
}

/*=============================================>>>>>
= Default typography styles =
===============================================>>>>>*/

// $regular: 400;
// $medium: 500;
// $semibold: 600;
// $bold: 700;

body {
  font-family: $font-family-base;
  color: $primary;
  font-size-adjust: auto;
  line-height: 1.9;
  text-align: left;
  word-wrap: break-word;
  text-rendering: optimizeLegibility;
  font-smoothing: antialiased;

  @include media($mobile-down) {
    font-size: 16px;
  }
}


// --------------------------------------------------------------------------
//   Headings
// --------------------------------------------------------------------------

h1 + h2,
h2 + h3,
h3 + h4,
h5 + h6 {
  margin-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-family__base;
  text-transform: uppercase;
  line-height: 1.2;
  &:first-child {
    margin-top: 0;
  }
}

h1 { font-size: 46px; }
h2 { font-size: 39px; }
h3 { font-size: 34px; }
h4 { font-size: 30px; }
h5 { font-size: 16px; }
h6 { font-size: 12px; }

@include media($mobile-down) {

  h1 { font-size: 32px; }
  h2 { font-size: 30px; }
  h3 { font-size: 26px; }
  h4 { font-size: 20px; }
  h5 { font-size: 14px; }
  h6 { font-size: 12px; }

}

// --------------------------------------------------------------------------
//   Content Elements
// --------------------------------------------------------------------------

a {
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

p {
  margin-top: 0;
  margin-bottom: 20px;
}

figure {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
}

hr {
  display: block;
  margin: 20px 0;
  border: 0;
}

blockquote {
  margin: 0 0 20px;
  font-family: cambria, georgia, times, serif;
  font-style: italic;
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

table {
  width: 100%;
}
