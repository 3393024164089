/* ------------------------------------------------------
  Footer
------------------------------------------------------ */

footer {
  background-color: $secondary;
  .footer {
    position: relative;
    padding: 40px 30px 50px;
    @include media($tablet-down) {
      padding: 30px 20px;
    }
    @include media($mobile-down) {
      padding-left: 5%!important;
      padding-right: 5%!important;
    }
    a {
      color: $white!important;
    }
    .home-button {
      position: absolute;
      top: 40px;
      right: 30px;
      width: 20px;
      height: 20px;
      background-image: url(../images/HomeButtonWhite.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      transition: transform 0.3s ease;
      @include media($tablet-down) {
        right: 25px;
      }
      &:hover {
        transform: scale(1.15);
        cursor: pointer;
        background-image: url(../images/HomeButtonPurple.svg);
      }
    }

    .footer-container {
      display: flex;
      flex-direction: row;
      vertical-align: middle;
      color: $white;
      border-bottom: 1px solid $white;

      @include media($mobile-down) {
        flex-direction: column;
      }

      .footer-column {
        display: block;
        flex: 1;

        font-size: 15px;
        @include media($mobile-down) {
          flex: none;
          width: 100%;
        }

        p {
          width: 100%;
          margin: 0;
          margin-bottom: 10px;
          color: $white!important;
        }
      }

      .logo-column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .footer-logo {
          display: inline-block;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          background-image: url(../images/CascadaWhiteLogo.svg);
          width: 210px;
          height: 40px;
          margin: 10px 5px 10px 0;
        }
      }

      .contact-column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: middle;

        p {
          padding: 10px;
          margin: 0px;
          @include media($mobile-down) {
            flex: none;
            width: 100%;
            padding: 10px 0px;
            margin-bottom: 20px;
          }
        }
      }

      .signature-column {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        text-align: right;
        @include media($mobile-down) {
          align-items: flex-start;
          text-align: left;
          margin-bottom: 10px;
        }
        img {
          max-width: 200px;
        }
        .signature {
          margin-top: 5px;
          a {
            color: $white;
          }
        }
      }
    }
  }
}
