#sales {
  &.gray-theme {
    background: $background-tertiary;
  }
  @include media($tablet-down) {
    margin-bottom: 0;
  }
  .wrap {
    padding: 50px;
    @include media($tablet-down) {
      padding: 20px 30px;
    }
  }
  .sales-heading {
    color: $tertiary;
    font-weight: bold;
    margin: 30px 0px;
    font-family: "dosisbold", "Helvetica Neue", Helvetica, Arial, sans-serif;
    @include media($tablet-down) {
      font-size: 30px;
    }
  }
  ol {
    // @include clearfix();
    padding: 0;
    list-style-type:  none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    li {
      position: relative;
      width: calc(50% - 5px);
      padding: 35px 40px 20px;
      counter-increment: step-counter;
      margin: 0 10px 10px 0;
      background-color: $white;
      border-radius: 5px;
      box-shadow: 1px 1px 5px #EEE;
      transition: all 0.3s ease-in-out;
      @include media($tablet-down) {
        width: 100%;
        margin: 0 0 10px 0;
      }
      &:nth-child(2n) {
        margin: 0 0 10px 0;
      }
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 20px;
        width: 100px;
        background-image: url('../images/StrokedLineMask.svg');
        background-position: center;
        background-repeat: repeat;
        background-size: contain;
      }
      .text-content {
        &:before {
          content: counter(step-counter);
          margin-right: 20px;
          background-color: $secondary;
          color: $white;
          font-size: 20px;
          font-weight: bold;
          padding: 13px 27px;
          border-radius: 80px;
          float: left;
          display: inline;
        }
        article {
          width: 75%;
          float: left;
          display: inline;
        }
        h3 {
          font-size: 22px;
          font-family: $heading-bold;
          color: $secondary;
          padding: 0 0 5px;
          margin: 0;
        }
        p {
          font-size: 15px;
        }
        @include media($mobile-down) {
          &:before {
            display: none;
            float: none;
            width: 20px;
          }
          article {
            width: 100%;
          }

        }
      }
      &:hover {
        background-color: $secondary;
        color: $white;
        .text-content {
          &:before {
            color: $secondary;
            background-color: $white;
          }
          h3 {
            color: $white;
          }
        }
      }
      @include media($tablet-down) {
        flex-direction: column;
      }
    }
  }
}
